@tailwind base;
@tailwind components;
@tailwind utilities;

.font-lota{
    font-family: 'Lota Grotesque Alt 1', sans-serif;
    font-family: 'Lota Grotesque Alt 2', sans-serif;
    font-family: 'Lota Grotesque Alt 3', sans-serif;
    font-family: 'Lota Grotesque', sans-serif;

}